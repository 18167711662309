/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:fb310929-cd6c-465e-9032-612128af5cbd",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_AtIUZB8Uh",
    "aws_user_pools_web_client_id": "4p35r2l5vr75epmqq6b3qk3r5p",
    "oauth": {},
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "SupportForm-staging",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "SupportFormApi",
            "endpoint": "https://v3whzc55id.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
