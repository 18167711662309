import React, { useState } from 'react';

 function ValidateTheForm (props) {
   const initialState = {
      username: '',
      error: ''
    };
  
const [statethis, setState] = useState(initialState)

const handleUsernameChange = e => {
  console.log('handleUsernameChange()');
  const target = e.target;
  setState({
    username: target.value,
    error: target.validationMessage
  });
}

const handleSubmit = e => {
  console.log('handleSubmit() Submit form with state:', statethis);
  e.preventDefault();
}


  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="form-group">
        <label htmlFor="username">Name</label>
        <input
          id="username"
          name="username"
          value={statethis.username}
          onChange={handleUsernameChange}
          placeholder="Name"
          pattern="[A-Za-z]{3}"
          className="form-control" />
        <div className="invalid-feedback d-block">
          {statethis.error}
        </div>
      </div>

      <button className="btn btn-primary">Submit</button>
    </form>
  );
}



    export default  ValidateTheForm