import React from "react";
import logo_header from '../images/logo.png';
import logo_banner from '../images/primacraftheader.png';
import '../App.css';

const Home = () => {
    return (
        
      
    <body>

        <div class="container">
          <div class="header"><a href="home"><img src={logo_header} alt="Banner" width="374" height="117" class="responsive3" /></a>    
         </div>
      
        
        <br />
        <br />
        <br />

          <div class="sidebar1">
        <br /><br />
            <img src={logo_banner} alt="PrimaCraft" class="responsive2" width="499" height="109" />
          
            
        
               <br/>
                  <br/>
                
            <p>Con el software de digitalización PrimaCraft Supreme puede conseguir bordado de calidad profesional. Incluye herramientas fáciles de usar para crear y editar atractivos diseños. </p>
        </div>

          <div class="content">
          <br />
          <br />
            <a href="https://media.primacraftsoftware.com/downloads/PrimaCraftSupreme380410-14-2021.zip" class="myButton" style={{color:'white'}} >Descargar Software</a>
            <br />
            <br />
            <b>Software:</b> 3804 - <b>Date:</b>10/14/2021
            <br />
            <br />
                
           <a href="https://media.primacraftsoftware.com/downloads/PrimaCraftSupreme_ES.pdf" class="myButton" style={{color:'white'}} >Descargar Manual</a>
            <br />
            <br />
            <a href="support" class="myButton" style={{color:'white'}} >Soporte Técnico</a>
          </div>

        <br />
        <br />
        <br />
        
            <div class="footer">
            <center>
            <p>© 2022 Primacraft Supreme. Todos los derechos están reservados. </p>
            </center>
          </div>

        </div>
    </body>

    );
}

export default Home;