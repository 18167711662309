import React from "react";
import '../App.css';

const NotFoundPage = () => {
    return (
     <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
       <center>
    <h1> 404 PAGE NOT FOUND</h1>
      </center>

     </div>
  
          );
        }

export default NotFoundPage;