
import './App.css';



import React, { Component } from 'react';
import { 
  BrowserRouter as Router,
  Route,
  Switch, 
} from "react-router-dom";


//Pages 
import MainPage from "./pages";
import Home from './pages/home';
import SupportPage from './pages/support';



import CheckBox from './pages/checkbox';
import ValidateTheForm from './pages/validate';
import FormTest from './pages/formtest';
import ContactPage from './pages/contact';
import HelpTutorialPage from './pages/helptutorial';

import NotFoundPage from './pages/404';

class App extends Component {
  render(){
    return <Router>
      <Switch>
      <Route exact path="/" component={MainPage} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/support" component={SupportPage} />

  
      <Route exact path="/checkbox" component={CheckBox} />
      <Route exact path="/validate" component={ValidateTheForm} />
      <Route exact path="/formtest" component={FormTest} />
      <Route exact path="/contact" component={ContactPage} />
      <Route exact path="/help" component={HelpTutorialPage} />

      <Route component={NotFoundPage} />

      </Switch>
    </Router>

  }
}

export default App;
