import React from "react";
import logo_header from '../images/logo.png';
import '../App.css';



        
  const SupportPage = () => {
        return (
      
  
    <body>
        <div class="container">
          <div class="header"><a href="home"><img src={logo_header} alt="Banner" width="374" height="117" class="responsive3" /></a>    
         </div>
      
        <br />
      

    <div class="sidebar2">
    <br />

    <h2>Contáctenos para Soporte:</h2>
    
    


    <form action="mailto:soportehome1@casadiaz.biz?subject=Soporte Técnico"  method="post" enctype="text/plain">
      Fecha:<br />
      <input 
      type="text" 
      id="date" 
      name="Fecha" 
      placeholder="MM/DD/YY" 
      pattern="\d{1,2}/\d{1,2}/\d{4}" 
      class="datepicker" 
      oninvalid="this.setCustomValidity('You must add the date.')" 
      onchange="this.setCustomValidity('You must add the date.')" 
      required
      title="Este es un mensaje de error"  /><br />
      <br />
      Nombre:<br />
      <input 
      type="text" 
      id="name" 
      name="Nombre" 
      pattern="[a-zA-Z]*" 
      placeholder="Nombre"   
      oninvalid="this.setCustomValidity('You must add your name.')" 
      onchange="this.setCustomValidity('You must add your name.')" 
      required
      title="Este es un mensaje de error" /><br />
      <br />
      Apellido:<br />
      <input 
      type="text" 
      id="lastname"
      name="Apellido" 
      placeholder="Apellido"   
      oninvalid="this.setCustomValidity('You must add your Last name.')" 
      onchange="this.setCustomValidity('You must add your Last name.')" 
      required
      title="Este es un mensaje de error"/><br />
      <br />
      Correo Electrónico:<br />
      <input 
      type="email"  
      id="email" 
      name="Correo Electrónico" 
      placeholder="Correo electrónico"  
      oninvalid="this.setCustomValidity('You must add your email.')" 
      onchange="this.setCustomValidity('You must add your email.')"
      required
      title="Este es un mensaje de error" /><br />
      <br />
      Numero Telefónico:<br />
      <input 
      type="text"  
      name="Numero Telefónico" 
      pattern="[0-9]+" 
      minlength="9" 
      minlengthmax="15" 
      onkeypress="return isNumber(event);" 
      placeholder="123-456-7890" 
      oninvalid="this.setCustomValidity('You must add your phonenumber.')" 
      onchange="this.setCustomValidity('You must add your phonenumber.')"
      required
      title="Este es un mensaje de error"/><br />
      <br />
      Descripción:<br />
      <textarea 
      name="Descripción"  
      id="input-font" 
      minlength="5"  
      maxlength="1000" 
      class="input-text"  
      oninvalid="this.setCustomValidity('This cant be left blank.')" 
      onchange="this.setCustomValidity('This cant be left blank.')" 
      cols="50" 
      rows="5" 
      required
      title="Este es un mensaje de error" ></textarea>
      <br /><br />
      <input type="submit" class="myButton" id="submit" value="Enviar"/>  &nbsp;
      <input type="reset"  class="myButton" value="Reiniciar"/>
      <br />
      <br />
      Al hacer clic en “enviar” acepto ser contactado por correo y/o por teléfono.
    </form> 

 

   

    </div>

    

        <br />
        <br />
        <br />
        
            <div class="footer">
            <center>
            <p>© 2021 Primacraft Supreme. Todos los derechos están reservados. </p>
            </center>
          </div>

        </div>
    </body>

    );
}

export default SupportPage;