import {  useState } from "react";
import Amplify from "aws-amplify";
import { API } from 'aws-amplify';
import { Button, Form} from 'react-bootstrap';
import logo_header from '../images/logo.png';


import awsExports from "../aws-exports";

Amplify.configure(awsExports);

async function addContact() {
  const data = {
    body: {
      date: formState.date,
      firstname: formState.firstname,
      lastname: formState.lastname,
      email: formState.email,
      phonenumber: formState.phonenumber,
      message: formState.message
    }
  };

  console.log(data);
  const apiData = await API.post('SupportFormApi', '/support', data);
  console.log({ apiData });
  alert('El correo se ha enviado, lo llevaremos a la página de inicio.');
  window.location.href = "/home"
}

const formState = { date: '', firstname: '', lastname: '', email: '', phonenumber: '', message: '' };

function updateFormState(key, value) {
  formState[key] = value;
}

const Contact = () => {
    const [isDisabled, setIsDisabled] = useState(true);
    const [checked, setChecked] = useState(false);
    
  
    const canBeSubmitted = () => {
      return checked ? setIsDisabled(true) : setIsDisabled(false);
    };
  
    const onCheckboxClick = () => {
      setChecked(!checked);
      return canBeSubmitted();
    };

        const initialState = {
           date:'',
           name: '',
           lastname: '',
           email:'',
           error: ''
         };
       
     const [statethis, setState] = useState(initialState)

     const handleDate = e => {
        console.log('handleDate()');
        const target = e.target;
        setState({
          name: target.value,
          errordate: target.validationMessage 
        });
      }
     
     const handleName = e => {
       console.log('handleName()');
       const target = e.target;
       setState({
         name: target.value,
         errorname: target.validationMessage 
       });
     }

     const handleLastName = e => {
       console.log('handleLastName()');
       const target = e.target;
       setState({
         name: target.value,
         errorlastname: target.validationMessage 
       });
     }

     const handleEmail = e => {
        console.log('handleEmail()');
        const target = e.target;
        setState({
          name: target.value,
          erroremail: target.validationMessage 
        });
      }

      const handlePhone = e => {
        console.log('handlePhone()');
        const target = e.target;
        setState({
          name: target.value,
          errorphone: target.validationMessage 
        });
      }

      const handleMessage = e => {
        console.log('handleMessage()');
        const target = e.target;
        setState({
          name: target.value,
          errormessage: target.validationMessage 
        });
      }

     
     const handleSubmit = e => {
       console.log('handleSubmit() Submit form with state:', statethis);
       e.preventDefault();
     }

  return (

    
    
        <div  className="container">
          <div  className="header"><a href="home"><img src={logo_header} alt="Banner" width="374" height="117"  className="responsive3" /></a>    
         </div>
      
        <br />
       

    <div  className="sidebar2">
    <br />
    <h2>Contáctenos para Soporte:</h2>
            <Form onSubmit={handleSubmit}>
            <Form.Group>
            <Form.Label>Fecha:</Form.Label>
            <br />
            <Form.Control 
       required
       validationErrors={{
         isDefaultRequiredValue: 'Por favor complete'
       }}
            pattern="\d{1,2}/\d{1,2}/\d{4}" 
            onChange={e => { handleDate(e); updateFormState('date', e.target.value);}}
            placeholder="MM/DD/YY"  />
              <div className="invalid-feedback d-block">
          {statethis.errordate}
        </div>
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label>Nombre:</Form.Label>
            <br />
            <Form.Control 
            required 
            value={statethis.username}
            onChange={e => { handleName(e); updateFormState('firstname', e.target.value);}}
            pattern="[a-zA-Z]+[^0-9]"
            placeholder="NOMBRE"  />
            <div className="invalid-feedback d-block">
          {statethis.errorname}
        </div>
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label>Apellido:</Form.Label>
            <br />
            <Form.Control 
            required 
            onChange={e => {handleLastName(e); updateFormState('lastname', e.target.value);}}
            pattern="[a-zA-Z]+[^0-9]"
            placeholder="APELLIDO"  />
               <div className="invalid-feedback d-block">
          {statethis.errorlastname}
        </div>
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label>Correo Electrónico:</Form.Label>
            <br />
            <Form.Control 
            required 
            type="email"  
            onChange={e => {handleEmail(e); updateFormState('email', e.target.value);}}
            placeholder="EMAIL@EMAIL.COM"  />
                 <div className="invalid-feedback d-block">
          {statethis.erroremail}
        </div>
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label>Numero Telefónico:</Form.Label>
            <br />
            <Form.Control 
            required 
            onChange={e => {handlePhone(e); updateFormState('phonenumber', e.target.value);}}
            placeholder="123-456-7890"
            pattern="^[0-9-]+$" 
            minlength="9" 
            minlengthmax="15" 
              />
        <div className="invalid-feedback d-block">
          {statethis.errorphone}
        </div>
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label>Descripción:</Form.Label>
            <br /><textarea class="Form.Control" 
            required
            id="input-font" 
            cols="50" 
            rows="5" 
            minlength="5"  
            maxlength="1000" 
            onChange={e => {handleMessage(e); updateFormState('message', e.target.value);}}
            placeholder="DESCRIPCIÓN DEL PROBLEMA"  />
        <div className="invalid-feedback d-block">
          {statethis.errormessage}
        </div>
          </Form.Group>
          <br />
          <input type="checkbox" onClick={onCheckboxClick} /> Al marcar "la casilla", acepta ser contactado por correo y/o por teléfono.
          <br />
          <br />
          <Button method="POST"  disabled={isDisabled}  onClick={addContact}>Enviar</Button> &nbsp; <Button type="reset" value="Reset" class="myButton">Reiniciar</Button>
        </Form>

      
<br />




 

   

    </div>

    

        <br />
        <br />
        <br />
        
            <div  className="footer">
            <center>
            <p>© 2021 Primacraft Supreme. Todos los derechos están reservados. </p>
            </center>
          </div>

        </div>


    );
}



export default Contact;