import { useState } from "react";

const CheckBox = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [checked, setChecked] = useState(false);
  

  const canBeSubmitted = () => {
    return checked ? setIsDisabled(true) : setIsDisabled(false);
  };

  const onCheckboxClick = () => {
    setChecked(!checked);
    return canBeSubmitted();
  };

  return (
    <div className="app">
      <input type="checkbox" onClick={onCheckboxClick} />
      <button type="submit" disabled={isDisabled}>
        Submit
      </button>
    </div>
  );
  
};

export default CheckBox;