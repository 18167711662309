import React from "react";
import splash_logo from '../images/primacraft_splash.png';
import '../App.css';






const MainPage = () => {
    return (

        <React.Fragment>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <center><a href="home"><img src={splash_logo}  class="responsive" alt="splash_logo"  /></a></center>
        </React.Fragment>
                  
        
        
              
          
          );
        }

export default MainPage;